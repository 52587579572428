<app-modal #modalComponent>
    <div class="w-full max-w-md lg:px-4 pb-2">
        <h5>
           {{'create-topping.seleccione' | translate}}
        </h5>
     
        <div class="mt-2 flex justify-around flex-wrap gap-3">
            <div *ngFor="let item of list" (click)="selectedTopping(item)" [ngClass]="isSelected(item) ? 'badge-principal border-principal' : 'badge-category'" class="border-2 border-transparent cursor-pointer  w-32 text-center p-4 rounded-md flex flex-col justify-around items-center gap-2" >
                <img class="h-14" [src]="item.image ? item.image : imageDef" />
                <span class="font-extrabold" >{{item.name}}</span>
                <span >$ {{item.price}}</span>
            </div>
            <!-- <button *ngFor="let item of list" (click)="selectedTopping(item)" [ngClass]="isSelected(item) ? 'badge-principal ' : 'badge-category'" class="badge badge-rounded outline-border border-2 border-transparent badge-sm flex items-center gap-2" >
                <img class="w-10" [src]="item.image ? item.image : imageDef" />
                <span class="font-extrabold" >{{item.name}}</span>
                <span >$ {{item.price}}</span>
            </button> -->
        </div>
        
    
        <div class="mt-6 lg:mt-10 flex justify-end">
            <button (click)="close()" type="button" class="btn btn-link btn-sm mr-4 uppercase"> {{'buttons.cerrar' | translate}} </button>
            <!-- <button (click)="close()" type="button" class="btn btn-principal">{{'buttons.save' | translate}}</button> -->
        </div>
    </div>
</app-modal>

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToppingService } from 'src/app/service/toppings/topping.service';

import { ProductosService } from 'src/app/service/productos/productos.service';
import { ModalComponent } from 'src/app/shared/modal/modal/modal.component';
import { StorageService } from 'src/app/service/storage/storage.service';
import { TagsComponent as tagsComponent } from '../../../tags/modals/tags/tags.component';
import { SpinnerService } from 'src/app/service/spinner/spinner.service';

@Component({
  selector: 'app-toppings',
  templateUrl: './toppings.component.html',
  styleUrls: ['./toppings.component.scss']
})
export class ToppingsComponent implements OnInit, OnDestroy {

  current_page: number = 1;
  list : any[] = [];
  listNew : any[] = [];
  previousPage: number = 0;
  listFinish = false;

  imageDef: string = "https://puu.sh/ImNRs/0ab1b352dc.png";

  @ViewChild('modalComponent') modal:
  | ModalComponent<tagsComponent>
  | undefined;
  private subscriptions: Array<Subscription> = [];
  role:string = ''
  toppingsSelected:any[] = []
  constructor(
    private toppingsService: ToppingService,
    private cd: ChangeDetectorRef,
    private productService: ProductosService,
    private storage: StorageService,
    private spinner: SpinnerService,


  ) {  
      this.getRole(); 
      this.spinner.show()
    }

  ngOnInit(){
    this.loadTags()
    this.getToppingsSelected()
   }

  ngOnDestroy(){
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  selectedTopping(toppings){
    const result = this.toppingsSelected.find(({ id }) => id === toppings.id);
    if(result == undefined){
      this.toppingsSelected.push(toppings)
      this.productService.setToppings(this.toppingsSelected)
    }else{
      this.toppingsSelected = this.toppingsSelected.filter(topp => topp.id != toppings.id)
      this.productService.setToppings(this.toppingsSelected)
    }
  }

  isSelected(toppings){
    return this.toppingsSelected.find(({ id }) => id === toppings.id) != undefined;
  }


  deleted(idx:number){ this.toppingsSelected.splice(idx, 1) }


  getRole(){
    this.subscriptions.push(
      this.storage.getRole()
      .subscribe((rol:string) => rol !== '' && (this.role = JSON.parse(rol)) )
    )
  }


  getToppingsSelected(){
    this.subscriptions.push(
      this.productService.getToppings().subscribe(toppings => this.toppingsSelected = toppings )
    )   
  }

  loadTags(){
    if(this.role === 'store'){      
      this.toppingsService.getFullListPage()
      .then(res => {
          this.list = res.response
          // this.list = [...this.list,...res.response]
          // this.list = [...this.list,...res.response]
          // this.list = [...this.list,...res.response]
          // this.list = [...this.list,...res.response]
          this.spinner.hide()
          this.cd.detectChanges()
        })
    }else{
      this.toppingsService.getFullListPageAdmin()
      .then(res => {
        this.list = res.response
        this.spinner.hide()
        this.cd.detectChanges()
      })
    }
  }


  async close(): Promise<void> {
    await this.modal?.close();
  }

}
